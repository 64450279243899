// libraries
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// modules
import { t } from 'src/modules/i18n';
// styles
import './index.scss';

const Title = ({ tKey }) => {
  const { pageVariant } = useSelector((state) => state.remoteConfig);
  const titleClass = classNames('title', `title_type_${pageVariant}`);

  return <div className={titleClass}>{t(`${tKey}.page_title`)}</div>;
};

Title.propTypes = {
  tKey: PropTypes.string.isRequired,
};

export default Title;
