// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// actions
import { downloadApp } from 'src/store/app/actions';
// constants
import { PAGE_TYPES } from 'src/constants/general';
// modules
import Analytics from 'src/modules/analytics';
// hoc
import Wrapper from 'src/hoc/wrapper';
// components
import Loader from 'src/components/loader';
import MusicPage from 'src/screens/pages/music';
import HeadphonesPage from 'src/screens/pages/headphones';
import DancePage from 'src/screens/pages/dance';

const App = () => {
  const [containerHeight, setContainerHeight] = useState(550);
  const { loaded } = useSelector((state) => state.app);
  const { pageClickMode, autoSkip, pageVariant } = useSelector((state) => state.remoteConfig);

  useEffect(() => {
    const heightTimeout = setTimeout(() => {
      setContainerHeight(window.innerHeight);
      window.scrollTo(0, 0);
    }, 50);

    return () => {
      clearTimeout(heightTimeout);
    };
  });

  useEffect(() => {
    let autoSkipTimeout = null;

    if (autoSkip) {
      autoSkipTimeout = setTimeout(() => {
        Analytics.trackEvent('redirect', 'action');
        downloadApp();
      }, autoSkip * 1000);
    }

    return () => {
      clearTimeout(autoSkipTimeout);
    };
  }, [autoSkip]);

  const handleBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Analytics.trackEvent('install_now', 'click');
    downloadApp();
  };

  const handlePageClick = () => {
    if (pageClickMode) {
      Analytics.trackEvent('page', 'click');
      downloadApp();
    }
  };

  const SCREENS = {
    [PAGE_TYPES.MUSIC]: <MusicPage onClick={handleBtnClick} />,
    [PAGE_TYPES.HEADPHONES]: <HeadphonesPage onClick={handleBtnClick} />,
    [PAGE_TYPES.DANCE]: <DancePage onClick={handleBtnClick} />,
  };

  return (
    <Wrapper onClick={handlePageClick} height={containerHeight}>
      {!loaded ? <Loader /> : SCREENS[pageVariant]}
    </Wrapper>
  );
};

export default App;
