// libraries
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
// constants
import IMAGES from 'src/constants/images';
// styles
import './index.scss';

const Image = () => {
  const { pageVariant } = useSelector((state) => state.remoteConfig);
  const ImageClass = classNames('image', `image_type_${pageVariant}`);

  return (
    <img
      className={ImageClass}
      src={IMAGES[pageVariant.toUpperCase()].src}
      srcSet={IMAGES[pageVariant.toUpperCase()].srcSet}
      alt={pageVariant}
    />
  );
};

export default Image;
