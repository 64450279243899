// libraries
import React from 'react';
import PropTypes from 'prop-types';
// components
import Logo from 'src/components/small-logo';
import Button from 'src/components/button';
import Title from 'src/components/title';
import Image from 'src/components/image';
// modules
import { t } from 'src/modules/i18n';
// styles
import './index.scss';

const tKey = 'music_page';

const FirstPage = ({ onClick }) => (
  <div className="music-page">
    <header className="music-page__header">
      <Logo subtitle={t(`${tKey}.logo_subtitle`)} />
    </header>
    <main>
      <Image />
      <Title tKey={tKey} />
    </main>
    <footer>
      <Button onClick={onClick} />
    </footer>
  </div>
);

FirstPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FirstPage;
