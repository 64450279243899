// libraries
import { createAction } from 'redux-actions';
// modules
import RemoteConfig from 'src/modules/remote-config';
// types
import { SET } from './types';
// initial state
import { initialState } from './index';

const REMOTE_CONFIG_CACHE_TIME = 60;

const set = createAction(SET, (cfg) => ({ cfg }));

export function fetchRemoteConfig() {
  return (dispatch, getState) =>
    RemoteConfig.fetch(initialState, REMOTE_CONFIG_CACHE_TIME)
      .then((cfg) => {
        dispatch(set(cfg));
      })
      .catch((err) => {
        console.warn('fetchRemoteConfig err: ', err.message);
      })
      .then(() => getState().remoteConfig);
}

export function init() {
  return (dispatch) => dispatch(fetchRemoteConfig());
}
