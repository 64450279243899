// libraries
import { createAction } from 'redux-actions';
import Attribution from '@wowmaking/web2app';
// constants
import { APPSFLYER_LINK } from 'src/constants/general';
// modules
import Analytics from 'src/modules/analytics';
// actions
import { init as initRemoteConfig } from '../remote-config/actions';
// types
import { SET_LOADED } from './types';

const setLoaded = createAction(SET_LOADED);

export function load() {
  return (dispatch) => {
    Attribution.init();
    Analytics.init()
      .then(() => dispatch(initRemoteConfig()))
      .then(() => dispatch(setLoaded()))
      .then(() => Analytics.trackEvent('page', 'loaded'));
  };
}

export function downloadApp() {
  const link = Attribution.addAttributionToLink(APPSFLYER_LINK);
  Analytics.trackEvent('install_app', 'click');
  window.location.href = link;
}
