import queryString from 'query-string';
import EvTruck from '@magnus/react-native-evtruck';
import Session from '@wowmaking/react-native-analytics/core/helpers/session';

const Analytics = {
  init() {
    EvTruck.init({
      token: process.env.MAGNUS_TOKEN,
    });

    return Session.init(this).then(() => {
      const params = queryString.parse(window.location.search);
      this.setUserProperty('testmode', params.testmode);

      if (params.utm_source) {
        this.setUserProperty('utm_source', params.utm_source);
        this.setUserProperty('utm_medium', params.utm_medium);
        this.setUserProperty('utm_campaign', params.utm_campaign);
        this.setUserProperty('utm_adset', params.utm_adset);
        this.setUserProperty('utm_creative', params.utm_creative);
        this.setUserProperty('utm_content', params.utm_content);
        this.setUserProperty('utm_term', params.utm_term);
      }
    });
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params) {
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
    window.dataLayer?.push({ event, ...params });
  },
};

export default Analytics;
