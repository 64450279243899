// libraries
import React from 'react';
import PropTypes from 'prop-types';
// modules
import { t } from 'src/modules/i18n';
// components
import Button from 'src/components/button';
import Logo from 'src/components/small-logo';
import List from 'src/components/list';
import Title from 'src/components/title';
import Image from 'src/components/image';
// styles
import './index.scss';

const tKey = 'dance_page';

const ThirdPage = ({ onClick }) => (
  <div className="dance-page">
    <header className="dance-page__header">
      <Logo subtitle={t(`${tKey}.logo_subtitle`)} />
    </header>
    <main className="dance-page__main">
      <Image />
      <div className="dance-page__content">
        <Title tKey={tKey} />
        <List tKey={tKey} />
      </div>
    </main>
    <footer>
      <Button onClick={onClick} />
    </footer>
  </div>
);

ThirdPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ThirdPage;
