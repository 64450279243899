// libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
// constants
import { APP_NAME } from 'src/constants/general';
import { ICONS } from 'src/constants/icons';
// styles
import './index.scss';

const Logo = ({ subtitle }) => {
  const Icon = ICONS.LOGO;
  const { pageVariant } = useSelector((state) => state.remoteConfig);
  const subtitleClass = classNames(
    'small-logo__subtitle',
    `small-logo__subtitle_type_${pageVariant}`,
  );

  return (
    <div className="small-logo">
      <Icon className="small-logo__icon" />
      <div className="small-logo__titles">
        <h1 className="small-logo__title">{APP_NAME}</h1>
        <h2 className={subtitleClass}>{subtitle}</h2>
      </div>
    </div>
  );
};

Logo.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default Logo;
