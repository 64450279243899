// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// hooks
import useHeight from 'src/hooks/use-height';
// styles
import './index.scss';

const Wrapper = ({ children, height, onClick }) => {
  const { pageVariant } = useSelector((state) => state.remoteConfig);
  const containerHeight = useHeight();

  return (
    <div
      onClick={onClick}
      className={`wrapper wrapper_type_${pageVariant}`}
      style={{ height: height || containerHeight }}
    >
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

export default Wrapper;
