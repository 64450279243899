// libraries
import React from 'react';
import PropTypes from 'prop-types';
// constants
import { APP_NAME } from 'src/constants/general';
import IMAGES from 'src/constants/images';
// styles
import './index.scss';

const Logo = ({ subtitle }) => (
  <div className="logo">
    <img className="logo__images" src={IMAGES.LOGO.src} alt="logo" />
    <div className="logo__titles">
      <h1 className="logo__title">{APP_NAME}</h1>
      <h2 className="logo__subtitle">{subtitle}</h2>
    </div>
  </div>
);

Logo.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default Logo;
