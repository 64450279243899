// libraries
import React from 'react';
// styles
import './flare.scss';

export default () => (
  <div className="flare">
    <div className="flare__content" />
  </div>
);
