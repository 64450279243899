// libraries
import React from 'react';
// constants
import { ICONS } from 'src/constants/icons';
// styles
import './index.scss';

const Loader = () => {
  const Icon = ICONS.LOADER;

  return (
    <div className="loader-wrapper">
      <Icon className="loader" />
    </div>
  );
};

export default Loader;
