// libraries
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DeviceProps from '@magnus/react-native-device-props';
// store
import configureStore from './store';
// actions
import { load } from './store/app/actions';
// modules
import { Localization } from './modules/i18n';
// assets
import { version } from '../package.json';
// styles
import 'src/style/main.scss';

window.__DEV__ = !!process.env.REACT_APP_DEV;

export default (App) => {
  const { store, persistor } = configureStore();

  Localization.init();

  DeviceProps.setAppVersion(version);

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>,
    document.getElementById('root'),
  );

  store.dispatch(load(persistor));
};
