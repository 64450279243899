// music page
import MUSIC from 'src/assets/images/music-page/music-bg.webp';
import MUSIC2x from 'src/assets/images/music-page/music-bg@2x.webp';
import MUSIC3x from 'src/assets/images/music-page/music-bg@3x.webp';
// headphones page
import HEADPHONES from 'src/assets/images/headphones-page/headphones-bg.webp';
import HEADPHONES2x from 'src/assets/images/headphones-page/headphones-bg@2x.webp';
import HEADPHONES3x from 'src/assets/images/headphones-page/headphones-bg@3x.webp';
// dance page
import DANCE from 'src/assets/images/dance-page/dance-bg.webp';
import DANCE2x from 'src/assets/images/dance-page/dance-bg@2x.webp';
import DANCE3x from 'src/assets/images/dance-page/dance-bg@3x.webp';
// logo
import LOGO from 'src/assets/images/big-logo/logo.webp';

const IMAGES = {
  MUSIC: {
    src: MUSIC,
    srcSet: `${MUSIC} 1x, ${MUSIC2x} 2x, ${MUSIC3x} 3x`,
  },
  HEADPHONES: {
    src: HEADPHONES,
    srcSet: `${HEADPHONES} 1x, ${HEADPHONES2x} 2x, ${HEADPHONES3x} 3x`,
  },
  DANCE: {
    src: DANCE,
    srcSet: `${DANCE} 1x, ${DANCE2x} 2x, ${DANCE3x} 3x`,
  },
  LOGO: {
    src: LOGO,
  },
};

export default IMAGES;
