// libraries
import React from 'react';
import PropTypes from 'prop-types';
// components
import Button from 'src/components/button';
import Logo from 'src/components/big-logo';
import Title from 'src/components/title';
import Image from 'src/components/image';
// modules
import { t } from 'src/modules/i18n';
// styles
import './index.scss';

const tKey = 'headphones_page';

const SecondPage = ({ onClick }) => (
  <div className="headphones-page">
    <header className="headphones-page__header">
      <Logo subtitle={t(`${tKey}.logo_subtitle`)} />
    </header>
    <main className="headphones-page__main">
      <Image />
      <Title tKey={tKey} />
    </main>
    <footer>
      <Button onClick={onClick} />
    </footer>
  </div>
);

SecondPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SecondPage;
