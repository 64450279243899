// libraries
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// modules
import { t } from 'src/modules/i18n';
// components
import Flare from 'src/components/button/flare';
// styles
import './index.scss';

const Button = ({ onClick }) => {
  const { pageVariant, buttonTitle } = useSelector((state) => state.remoteConfig);
  const buttonClass = classNames('button', `button_type_${pageVariant}`);

  return (
    <button type="button" onClick={onClick} className={buttonClass}>
      {buttonTitle || t('button_title')}
      <Flare />
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Button;
