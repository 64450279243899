// libraries
import React from 'react';
import PropTypes from 'prop-types';
// modules
import { t } from 'src/modules/i18n';
// styles
import './index.scss';

const List = ({ tKey }) => {
  const list = t(`${tKey}.list`, { returnObjects: true });

  return (
    <ul className="list">
      {list &&
        list.map((item) => (
          <li key={item} className="list__item">
            <div className="list__bullet" />
            {item}
          </li>
        ))}
    </ul>
  );
};

List.propTypes = {
  tKey: PropTypes.string.isRequired,
};

export default List;
