// constants
import { PAGE_TYPES } from 'src/constants/general';
// types
import { SET } from './types';

export const initialState = {
  pageClickMode: true,
  buttonTitle: '',
  pageVariant: PAGE_TYPES.MUSIC,
  autoSkip: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };

    default:
      return state;
  }
};
